import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Logo from "./img/logo.png";
import LogoMain from "./img/logo_main.png";
import Post from "./img/post.png";
import "react-tabs/style/react-tabs.css";
import "./App.css";

function App() {
  return (
    <div className="App bg-black flex min-h-screen">
      <div className="hidden lg:block w-[20%] p-5 relative">
        <div className="absolute right-[50px]">
          <img
            src={LogoMain}
            alt="logo"
            className="max-w-[60px] rounded-full"
          />
          <ul className="flex flex-col gap-4 mt-10">
            <li>
              <a>
                <i className="fa-solid fa-house w-8"></i>
                <span className="inline-block ml-2">Home</span>
              </a>
            </li>
            <li>
              <a>
                <i className="fa-solid fa-bell w-8"></i>
                <span className="inline-block ml-2">Notifications</span>
              </a>
            </li>
            <li>
              <a>
                <i className="fa-solid fa-message w-8"></i>
                <span className="inline-block ml-2 ">Messages</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full h-screen overflow-y-scroll lg:w-[80%] border-r-2 border-r-[#111827] border-l-2 border-l-[#111827]">
        <div className="relative">
          <img
            className="w-full h-[300px] object-cover"
            src={Logo}
            alt="logo"
          />
          <img
            className="h-[120px] absolute rounded-full bottom-[-60px] left-6"
            src={LogoMain}
            alt="logo"
          />
        </div>
        <div className="text-right mt-4 mr-4">
          <a
            href="#"
            className="bg-white text-black rounded-2xl px-4 py-2 cursor-pointer text-sm"
          >
            Buy $SEPE
          </a>
        </div>
        <div className="ml-6 mt-6">
          <h4>SEPE</h4>
          <a
            href="https://twitter.com/SepeCoin"
            target="_blank"
            rel="noopener noreferrer"
            className=" text-blue-400"
          >
            @SepeCoin
          </a>
          <div className="flex flex-col gap-2 mt-5 sm:flex-row sm:gap-5">
            <a
              className="text-blue-400"
              href="https://t.me/SepeCoin"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://t.me/SepeCoin
            </a>
            <p>
              <i className="fa-solid fa-calendar-days opacity-30"></i>
              <span className="opacity-30 inline-block ml-2 text-sm">
                Launched May 2023
              </span>
            </p>
          </div>
          <div className="flex mt-5">
            <p>
              0 <span className="opacity-30 text-sm">Mcap</span>
            </p>
            <p className="ml-3">
              0 <span className="opacity-30 text-sm">SEPE/ETH</span>
            </p>
          </div>
        </div>

        <Tabs className="mt-6">
          <TabList className="pl-6">
            <Tab>Status</Tab>
            <Tab>Buy</Tab>
            <Tab>Chart</Tab>
            <Tab>NFT</Tab>
          </TabList>

          <TabPanel className="border-t-[1px] border-[#141414]">
            <div className="flex px-6 py-5 gap-4">
              <img
                src={LogoMain}
                alt="logo"
                className="w-[60px] h-[60px] rounded-full"
              />
              <div>
                <p>
                  <span>SEPE</span>
                  <span className="opacity-30 inline-block ml-3 text-sm">
                    @SepeCoin · May 2023
                  </span>
                </p>
                <p>
                  Welcome to the world of $SEPE! It's where the lazy charm of
                  Homer Simpson meets the infamous smirk of Pepe the Frog,
                  creating a crypto sensation unlike any other. We're not just a
                  meme coin; we're a couch-surfing, doughnut-munching,
                  meme-loving crypto community! With a governance as laid-back
                  as Homer at the control panel, we're redefining success one
                  "d'oh!" and "feels good man" at a time. So come join us, take
                  a seat on our virtual Springfield couch, and let's navigate
                  the crypto universe together, the $SEPE way!
                </p>
                <img
                  className="max-w-full rounded-xl mt-5 sm:max-w-[450px] md:max-w-[600px] lg:max-w-[700px]"
                  src={Post}
                  alt="post"
                />
                <ul className="flex gap-3 mt-5 sm:gap-10">
                  <li className="opacity-30 text-sm">
                    <i className="fa-regular fa-comment"></i>
                    <span className="inline-block ml-3">69</span>
                  </li>
                  <li className="opacity-30 text-sm">
                    <i className="fa-solid fa-retweet"></i>
                    <span className="inline-block ml-3">69</span>
                  </li>
                  <li className="opacity-30 text-sm">
                    <i className="fa-regular fa-heart"></i>
                    <span className="inline-block ml-3">696</span>
                  </li>
                  <li className="opacity-30 text-sm">
                    <i className="fa-solid fa-chart-simple"></i>
                    <span className="inline-block ml-3">969</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex px-6 py-5 gap-4 border-t-[1px] border-[#141414]">
              <img
                src={LogoMain}
                alt="logo"
                className="max-w-[60px] max-h-[60px] rounded-full"
              />
              <div>
                <p>
                  <span>SEPE</span>
                  <span className="opacity-30 inline-block ml-3 text-sm">
                    @SepeCoin · May 2023
                  </span>
                </p>
                <p>
                  Total Supply: 420,000,000,006,969; 0% Buy Fee & 0% Sell Fee
                </p>
                <ul className="flex gap-3 mt-5 sm:gap-10">
                  <li className="opacity-30 text-sm">
                    <i className="fa-regular fa-comment"></i>
                    <span className="inline-block ml-3">69</span>
                  </li>
                  <li className="opacity-30 text-sm">
                    <i className="fa-solid fa-retweet"></i>
                    <span className="inline-block ml-3">69</span>
                  </li>
                  <li className="opacity-30 text-sm">
                    <i className="fa-regular fa-heart"></i>
                    <span className="inline-block ml-3">696</span>
                  </li>
                  <li className="opacity-30 text-sm">
                    <i className="fa-solid fa-chart-simple"></i>
                    <span className="inline-block ml-3">969</span>
                  </li>
                </ul>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="border-t-[1px] border-[#141414]">
            <div className="flex px-6 py-5 gap-4">
              <img
                src={Logo}
                alt="logo"
                className="w-[60px] h-[60px] rounded-full"
              />
              <div>
                <p>
                  <span>SEPE</span>
                  <span className="opacity-30 inline-block ml-3 text-sm">
                    @SepeCoin · May 2023
                  </span>
                </p>
                <p>You can buy our token down below, or at Uniswap</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="border-t-[1px] border-[#141414]">
            <div className="flex px-6 py-5 gap-4">
              <img
                src={LogoMain}
                alt="logo"
                className="w-[60px] h-[60px] rounded-full"
              />
              <div>
                <p>
                  <span>SEPE</span>
                  <span className="opacity-30 inline-block ml-3 text-sm">
                    @SepeCoin · May 2023
                  </span>
                </p>
                <p>
                  Look at{" "}
                  <a
                    className="underline"
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x759445ded88f2eb44d27e079025597af741e5301"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dexscreen
                  </a>{" "}
                  or below.
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="border-t-[1px] border-[#141414]">
            {/* <div className="flex px-6 py-5 gap-4">
              <img
                src={LogoMain}
                alt="logo"
                className="max-w-[60px] max-h-[60px] rounded-full"
              />
              <div>
                <p>
                  <span>SEPE</span>
                  <span className="opacity-30 inline-block ml-3 text-sm">
                    @SepeCoin · May 10, 2023
                  </span>
                </p>
                <p>Here's a list of our community links.</p>
                <ul className="list-disc list-inside">
                  <li>
                    <a>Telegram</a>
                  </li>
                  <li>
                    <a>Twitter (the real one)</a>
                  </li>
                </ul>
              </div>
            </div> */}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default App;
